import logo from '../../res/logga.png';
import './home.scss'
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const Home = () => {
	const scrollTo = (ref: String) => {
		var element = document.querySelector('#' + ref)
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'center' })
		}
	}
	const isMobile = useMediaQuery({ query: `(max-width: 832px)` });
	return (
		<div>
			<Container className='presentation-area'>
				<h1>Välkommen till Anchored IT!</h1>
				<p className="basic-text">Vi är en liten konsultfirma med utgångspunkt i Norrtälje, vi brinner för att skapa stabila tjänster och att hjälpa våra kunder att nå deras mål.
				Vi förankrar vår kunskap och värderingar i en solid grund vilket gör att vi behåller vår position i en allt mer stormig värld. </p>
			</Container>
			<Container>
				<h2>Våra intressesfärer</h2>
				<p className="basic-text">IT-utveckling är ett brett spektra, där man kan specialisera sig i en rad olika ämnen. Utan att hävda att vi är experter i ett område, vågar vi ändå påstå att vi har en gedigen och bred kunskap inom utveckling, där det finns vissa områden som vi gärna uppmärksammar.</p>
			</Container>
			<div id="links">
				<div className="box">
					<div className="text" onClick={() => scrollTo('it-security')}><h3>IT-säkerhet</h3></div>
				</div>
				<div className="box">
					<div className="text" onClick={() => scrollTo('ux')}><h3>UX</h3></div>
				</div>
				<div className="box">
					<div className="text" onClick={() => scrollTo('clean-code')}><h3>Clean code</h3></div>
				</div>
			</div>
			<Container className="graph">
				<div className="horizontal-line" />
				<div className="vertical-line" />
				<img className="anchor"
					src={logo}
					alt="Anchored IT"
				/>
			</Container>
			<Container className='focus-area'>
				<div className="focus-header">
					<h3>IT-säkerhet</h3>
				</div>
				<div id="it-security" className='focus-body'>
					<p className="basic-text">IT-säkerhet är ett stort område, som gång på gång behöver uppmärksammas och arbetas med. Det händer allt för ofta att säkerhets-frågor glöms bort eller prioriteras ner, vilket leder till ökad risk för attacker som kan bli väldigt kostsamma. Vi menar att om ett system ska vara stabilt och robust, så behöver det även vara säkert. Något som man kan se på CIA-triaden, vilket är en välkänd modell för framtagande av policys och regelverk inom området informationssäkerhet.</p>
					<ul>
						<li className="basic-text">Confidentiality – Endast behöriga användare ska kunna ta del av informationen som den är avsedd för.</li>
						<li className="basic-text">Integrity – Säkerställer att informationen inte på något sätt har blivit felaktigt modifierat, utan att den är korrekt.</li>
						<li className="basic-text">Availability – Ge oavbryten åtkomst till data och system för legitima användare.</li>
					</ul>
					<p className="basic-text">Har man brister i något av områdena ovan, så kan det lätt bli kostsamt samt skada varumärket. Som utvecklare har vi ett ansvar till att diskutera frågorna ovan regelbundet.</p>
				</div>
			</Container>
			{isMobile ? (
				<Container className='focus-area'>
					<div className="focus-header">
						<h3>UX</h3>
					</div>
					<div id="ux" className="focus-body">
						<p className="basic-text">IT-system byggs för att lösa problem och många gånger så finns det en användare som använder systemet för att lösa problem. Men det spelar inte så stor roll hur bra systemet egentligen är, och vad det stödjer, förstår inte användaren hur systemet används så är det inte mycket till hjälp. Att bygga användarvänliga lösningar är många gånger klurigt. Många gånger vill man ha system som förstärker en känsla som varumärket vill förmedla, vara intuitivt och tydligt med vad den gör. Som utvecklare känner vi att vi har en skyldighet att hjälpa till med ideér eller försöka förtydliga där användargränssnittet kan upplevas otydligt.</p>
					</div>
				</Container>)
				:
				(<Container className='focus-area'>
					<div id="ux" className="focus-body">
						<p className="basic-text">IT-system byggs för att lösa problem och många gånger så finns det en användare som använder systemet för att lösa problem. Men det spelar inte så stor roll hur bra systemet egentligen är, och vad det stödjer, förstår inte användaren hur systemet används så är det inte mycket till hjälp. Att bygga användarvänliga lösningar är många gånger klurigt. Många gånger vill man ha system som förstärker en känsla som varumärket vill förmedla, vara intuitivt och tydligt med vad den gör. Som utvecklare känner vi att vi har en skyldighet att hjälpa till med ideér eller försöka förtydliga där användargränssnittet kan upplevas otydligt.</p>
					</div>
					<div className="focus-header">
						<h3>UX</h3>
					</div>
				</Container>)
			}
			<Container className='focus-area'>
				<div className='focus-header'>
					<h3>Clean code</h3>
				</div>
				<div id="clean-code" className='focus-body'>
					<p className="basic-text">Många har säkert hört om system som ingen vill utvecklare vill röra, för att man inte förstår vad koden gör. Skriva förståelig kod är inte alltid lätt, men som utvecklare har man en skyldighet att göra sitt bästa. För att åstadkomma detta hjälper många gånger metoder som TDD och DDD. Detta är områden som vi på Anchored IT studerar och arbetar aktivt med att förbättra. Allt för att få en så tydlig kod som möjligt, vilket förlänger tiden som systemet kan underhållas, samt underlättar vidareutveckling i.</p>
				</div>
			</Container>
		</div>
	);
};



export default Home;
