import React from 'react';
import '../home/home.scss'
import {Container} from "react-bootstrap";


const Contact = () => {
    return (
        <Container>
            <h1 className='header'>Kontakt</h1>
            <p className='basic-text'>För att komma i kontakt med <a href="mailto:info@anchoredit.se">oss!</a> så går det jättebra att maila: </p>
            <p className='basic-text'>info@anchoredit.se</p>
        </Container>
    )
}

export default Contact;
