import React from 'react'
import './index.scss'
import ReactCardFlip from "react-card-flip";
import Card from "react-bootstrap/Card";
import {Consultant} from "../../model/consultans";
import {Button} from "react-bootstrap";

interface IProps {
    consultant: Consultant
}

interface IState {
    cardIsFlipped?: boolean;
}

class ConsultCard extends React.Component<IProps, IState> {
    private consultant: Consultant;

    constructor(props: any) {
        super(props);
        this.state = {
            cardIsFlipped: false
        };
        this.flipCard = this.flipCard.bind(this);
        this.consultant = props.consultant
    }

    flipCard() {
        this.setState(previousState => ({cardIsFlipped: !previousState.cardIsFlipped}));
    }

    render() {
        return (
            <div className='consultCard'>
                <ReactCardFlip isFlipped={this.state.cardIsFlipped} flipDirection="horizontal">
                    <Card>
                        <Card.Img variant="top" src={process.env.PUBLIC_URL + '/images/' + this.consultant.imageName}/>
                        <Card.Body>
                            <div className='presentation'>
                                <Card.Title>{this.consultant.name} - {this.consultant.status}</Card.Title>
                                <Card.Text>
                                    {this.consultant.description}
                                </Card.Text>
                            </div>
                            <div className='toggle-area'>
                                <div className='center-bottom'>
                                    <Button variant='outline-secondary' onClick={() => this.flipCard()}> Se erfarenheter </Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className='backside'>
                        <Card.Body>
                            <div className='experience'>
                                {this.consultant.experience.map(ex =>
                                    <center key={ex.name}>
                                        <h3>{ex.name}</h3>
                                        <p className="basic-text">{ex.technics}</p>
                                    </center>
                                )}
                            </div>
                            <div className='center-bottom'>
                                <Button variant='outline-secondary' onClick={() => this.flipCard()}> Se presentation </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </ReactCardFlip>
            </div>
        )
    }
}

export default ConsultCard;
