import React from 'react'
import logo from '../../res/logga_anchored.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from "react-router-dom";
import './index.scss'

const MainNavbar = () => {
	const params = useLocation();
	const currentPath = params.pathname;
	return (
		<>
			<Navbar className="navbar" expand="lg">
				<Container>
					<Navbar.Brand className='brand' href="/">
						<img
							src={logo}
							alt="Anchored IT"
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="navbarScroll"/>
					<Navbar.Collapse id="navbarScroll">
						<Nav
							className="me-auto my-2 my-lg-0 d-flex gap-3"
							navbarScroll>
							<Nav.Link href="/consultants" className={currentPath === '/consultants' ? 'active-link':'inactive-link'}>Våra konsulter</Nav.Link>
							<Nav.Link href="/contact" className={currentPath === '/contact' ? 'active-link':'inactive-link'}>Kontakt</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default MainNavbar;
