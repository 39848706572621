import React from 'react';
import './App.css';
import MainNavbar from './components/navbar';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import Home from './pages/home/home';
import Consultants from './pages/consultants/consultants';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contact from "./pages/contact/contact";

function App() {
	return (
		<Router>
			<MainNavbar />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/consultants' element={<Consultants />} />
				<Route path='/contact' element={<Contact />} />
			</Routes>
		</Router>
	);
}

export default App;
