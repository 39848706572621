import React from "react";
import consultants_swe from '../../res/consults/consults-description-swe.json';
import ConsultCard from "../../components/consultCard";
import './consultants.scss'
import {Consultant} from "../../model/consultans";
import {Container} from "react-bootstrap";
class Consultants extends React.Component {

	consultants:Consultant[];
	constructor(props:any) {
		super(props);
		this.consultants = consultants_swe;
	}

	render() {
		return (
			<div>
				<Container>
					<h1 className='header'>
						Våra konsulter
					</h1>
					<div className='consult-presentation'>
					{this.consultants.map(consultant =>
						<ConsultCard
							consultant={consultant}
							key={consultant.name}/>)}
					</div>
				</Container>
			</div >

		);
	}
}






export default Consultants;
